<template>
  <div feature-icon v-editable="blok">
    <div class="feature-image">
      <img loading="lazy" v-if="blok.image?.filename" :src="optimizeImage(blok.image.filename)" :alt="`Feature Icon ${blok.image.alt}`" />
    </div>
    <h3 v-text="blok.title" class="feature-title"></h3>
    <div v-html="description" class="feature-description"></div>
  </div>
</template>
<style src="~/assets/scss/section/feature-icon.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const description = computed(() => useRichText(props?.blok?.description || {}));
</script>
